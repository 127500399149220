














import Vue from 'vue'

export default Vue.extend({
  props: {
    name: String,
    url: String,
    publishDate: String,
    current: Boolean
  },
  computed: {
    color(): string {
      return this.publishDate ? 'success' : undefined
    },
    tooltip(): string {
      const publishDateString = this.$formatter.datetime(this.publishDate)
      const name = this.name

      if (!this.publishDate) {
        return `This listing has never been published to '${name}'.`
      }

      if (this.current) {
        return `This listing was published to '${name}'\non ${publishDateString}.`
      }

      return `This listing has changed since it was last\npublished to '${name}' on ${publishDateString}. `
    },
    outlined(): boolean {
      return !this.current
    }
  }
})
