import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,_vm._g(_vm._b({attrs:{"item-key":"id","headers":_vm.headers.filter(function (h) { return !_vm.dense || !h.denseHidden; }),"footer-props":_vm.footerProps,"items":_vm.listings,"loading":_vm.loading,"server-items-length":_vm.listingsCount,"options":_vm.options,"must-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('TextField',{attrs:{"autofocus":"","prepend-inner-icon":"$search","placeholder":_vm.searchPlaceholder,"hide-details":"","clearable":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})]},proxy:true},{key:"item.displayName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'Listing', params: { id: item.id } },"target":_vm.target}},[_vm._v(_vm._s(item.displayName))])]}},{key:"item.startDate",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',{attrs:{"title":item.endDateLabel}},[_vm._v(_vm._s(_vm._f("date")(value)))])]}},{key:"item.extendedEndDate",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.modifiedOn",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.publishOn",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.retirementAnnouncementDate",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("date")(value)))]}},{key:"item.autoPublish",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("bool")(value)))]}},{key:"item.approved",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("bool")(value)))]}},{key:"item.reviewed",fn:function(ref){
var value = ref.value;
return [(!value)?_c(VIcon,{attrs:{"small":"","color":"warning","title":"Listing has source data changes that need to be reviewed."}},[_vm._v("$warning")]):_vm._e()]}},{key:"item.actionItem",fn:function(ref){
var item = ref.item;
return [(item.actionItems.length)?_c(VIcon,{attrs:{"small":"","color":"blue","title":_vm.getActionItemsTooltip(item.actionItems)}},[_vm._v("$flag")]):_vm._e()]}},{key:"item.environments",fn:function(ref){
var item = ref.item;
return [_c('ListingEnvironmentStatuses',{staticClass:"text-no-wrap",attrs:{"listing":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.isRemoved)?_c(VBtn,{attrs:{"icon":"","title":"Archive"},on:{"click":function($event){return _vm.archiveListing(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$archive")])],1):_c(VBtn,{attrs:{"icon":"","title":"Restore"},on:{"click":function($event){return _vm.restoreListing(item)}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("$restore")])],1)]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }